import { Row, Image } from '@printi/printi-components';
import styled from 'styled-components';

export const RowError = styled(Row)`
  margin-top: 68px;
`;

export const ErrorTitle = styled.text`
  margin-top: 68px;
  color: #009bff;
  font-size: 48px;
  line-height: 120%;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.96px;

  @media screen and (max-width: 320px) {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 0.25px;
    margin: 0 0 20px 10px;
  }

  @media screen and (min-width: 321px) and (max-width: 360px) {
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 0.25px;
    margin: 0 0 20px 10px;
  }
  @media screen and (min-width: 361px) and (max-width: 391px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 0.25px;
    margin: 0 0 20px 10px;
  }
  @media screen and (min-width: 392px) and (max-width: 430px) {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 0.25px;
    margin: 0 0 20px 10px;
  }
  @media screen and (min-width: 768px) and (max-width: 890px) {
    margin-top: 68px;
    color: #009bff;
    font-size: 30px;
    line-height: 120%;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.96px;
  }
`;

export const ErrorDescription = styled.div`
  width: 606px;
  margin-top: 24px;
  font-family: 'Circular';
  font-size: 20px;
  line-height: 140%;
  font-weight: 700;
  font-style: normal;
  color: #2c2c3a;

  p {
    font-family: 'Circular';
    font-size: 18px;
    font-style: normal;
    line-height: 140%;
    font-weight: 300;
    color: #2c2c3a;
  }
  @media screen and (max-width: 320px) {
    width: 150px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
      margin-bottom: 80px;
    }
  }
  @media screen and (min-width: 321px) and (max-width: 360px) {
    width: 200px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
      margin-bottom: 80px;
    }
  }
  @media screen and (min-width: 361px) and (max-width: 391px) {
    width: 200px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
      margin-bottom: 80px;
    }
  }
  @media screen and (min-width: 392px) and (max-width: 430px) {
    width: 250px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
      margin-bottom: 80px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 890px) {
    width: 450px;
    margin-top: 24px;
    font-family: 'Circular';
    font-size: 20px;
    line-height: 140%;
    font-weight: 700;
    font-style: normal;
    color: #2c2c3a;
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
    }
  }
  }
`;

export const ErrorImage = styled(Image)`
  margin: -140px 0 0 50px;
  width: 400px;
  height: 400px;

  @media screen and (max-width: 320px) {
    width: 250px;
    height: 200px;
    margin: 0 0 0 10px;
  }
  @media screen and (min-width: 321px) and (max-width: 360px) {
    width: 250px;
    height: 200px;
    margin: 0 0 0 10px;
  }
  @media screen and (min-width: 361px) and (max-width: 391px) {
    width: 250px;
    height: 200px;
    margin: -70px 0 0 10px;
  }
  @media screen and (min-width: 392px) and (max-width: 430px) {
    width: 250px;
    height: 200px;
    margin: -60px 0 0 10px;
  }
  @media screen and (min-width: 768px) and (max-width: 890px) {
    width: 400px;
    height: 400px;
    margin: -140px 0 0 10px;
  }
`;
export const HomeButton = styled.button`
  margin-top: 24px;
  border-radius: 4px;
  gap: 10px;
  padding: 8px 28px;
  background-color: #33afff;
  color: #2c2c3a;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  font-family: 'Circular';
  justify-content: center;
  align-items: center;
  font-style: normal;

  @media screen and (max-width: 320px) {
    display: flex;
    width: 300px;
    padding: 8px 22px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  @media screen and (min-width: 321px) and (max-width: 360px) {
    display: flex;
    width: 340px;
    padding: 8px 22px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  @media screen and (min-width: 361px) and (max-width: 391px) {
    display: flex;
    width: 355px;
    padding: 8px 22px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0 auto;
  }
  @media screen and (min-width: 392px) and (max-width: 430px) {
    display: flex;
    width: 395px;
    padding: 8px 22px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`;

export const RowTitleCategories = styled(Row)`
  margin-top: 68px;
`;
export const TitleCategories = styled.div`
  h2 {
    font-family: 'Circular';
    color: #2c2c3a;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 0.32px;
  }
  @media screen and (max-width: 767px) {
    h2 {
      display: none;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 890px) {
    h2 {
      display: none;
    }
  }
`;

export const RowSupportChannels = styled(Row)`
  margin-top: 68px;

  @media screen and (max-width: 320px) {
    margin-top: 0;
  }

  @media screen and (min-width: 321px) and (max-width: 360px) {
    margin-top: 40px;
  }
`;

export const Line = styled.div`
  hr {
    stroke-width: 1px;
    color: #bbc0c4;
    max-width:90%;
    margin-top: -30px;
  }
  @media screen and (min-width: 768px) {
    hr {
      display: none;
  }
`;

export const SupportChannels = styled.div`
  h2 {
    font-family: 'Circular';
    color: #2c2c3a;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 0.32px;
  }
  p {
    margin-top: 24px;
    margin-bottom: 40px;
    font-family: 'Circular';
    color: #2c2c3a;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
  }

  @media screen and (max-width: 320px) {
    margin-top: 0;
    h2 {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%;
      letter-spacing: 0.18px;
    }
    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
    }
  }

  @media screen and (min-width: 321px) and (max-width: 360px) {
    h2 {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%;
      letter-spacing: 0.18px;
    }

    p {
      font-size: 15px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
    }
  }
  @media screen and (min-width: 361px) and (max-width: 414px) {
    h2 {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%;
      letter-spacing: 0.18px;
      margin-top: -10px;
    }
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
    }
  }
`;
