import React, { memo } from 'react';

import { Col, Text } from '@printi/printi-components';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import * as S from './styles';

const MainCategories = dynamic(() => import('../../Home/MainCategories'));
const ChannelOptions = dynamic(() => import('../../../components/ChannelOptions'));

const ErrorPage404 = () => {
  const router = useRouter();
  const handleButtonClick = () => {
    router.push('/');
  };
  return (
    <>
      <S.RowError>
        <S.ErrorTitle as="h1" weight="bold" size="xlg" margin="0 0 1rem">
          Poxa, esta página não existe
        </S.ErrorTitle>
        <Col xs={6} md={7}>
          <S.ErrorDescription>
            <span>
              <b>Erro 404</b>
            </span>
            <Text> Confira se você digitou algo errado. Talvez o endereço não esteja mais disponível na web.</Text>
          </S.ErrorDescription>
          <S.HomeButton onClick={handleButtonClick}>Ir para página inicial</S.HomeButton>
        </Col>
        <Col xs={6} md={5}>
          <S.ErrorImage src="./images/errors/imageError.png"></S.ErrorImage>
        </Col>
      </S.RowError>
      <S.RowTitleCategories>
        <Col xs={12}>
          <S.TitleCategories>
            <Text as="h2" weight="bold" size="xlg" margin="0 0 1rem">
              Categorias de destaque
            </Text>
          </S.TitleCategories>
        </Col>
      </S.RowTitleCategories>
      <MainCategories />
      <S.Line>
        <hr />
      </S.Line>
      <S.RowSupportChannels>
        <Col xs={12}>
          <S.SupportChannels>
            <Text as="h2" weight="bold" size="xlg" margin="0 0 1rem">
              Não encontrou o que queria?
            </Text>
            <Text>
              A Printi está aqui para descomplicar sua vida e seu negócio. Conte com os nossos canais de atendimento
              para auxiliar no que precisar! Por chat, telefone ou atendimento corporativo, oferecemos rapidez e
              eficiência.
            </Text>
          </S.SupportChannels>
          <ChannelOptions showHelpCenterLink />
        </Col>
      </S.RowSupportChannels>
    </>
  );
};

export default memo(ErrorPage404);
